<template>
  <section class="image-canvas-container" @dragover.prevent @dragenter.prevent @drop="onHandleDropImage">
    <div class="toolkit-panel">
      <label for="file-upload" v-bind:class="{'btn-primary-disabled':isInpainting||isWebsocketConnectFailure}">
        <div class="btn-primary">
          <img src="/images/svg/image-upload.svg" alt="上传图片" width="20">
          <input id="file-upload" name="file-upload" type="file" style="display: none;"
                 accept="image/jpeg, image/png, image/webp, image/bmp"
                 v-on:change="onChangeImage">
        </div>
      </label>
    </div>
    <div class="editor-container"
         v-bind:class="{'editor-canvas-loading':isInpainting}">
      <!-- 缩放控件参数参考：https://www.npmjs.com/package/@coddicat/vue-pinch-scroll-zoom/v/3.0.1
           设置 width、height 后，会反应到 transform: transform-origin: 0px 0px; 上，导致移动图片位置受限。
           注意点：
           （1）width、height 的设置会反应到 style="width:1px;height:1px;" 不设置 width、height 无法缩放。
               看源码发现，平移功能源码上获取平移位置的方法是 getContainerStyle()，如果设置 width、height 平移就不生效，
               打印 translate 变量发现，位置参数（this.axisX.point、this.axisY.point）始终没有变过，但平移是触发了的。
               于是插断电 this.axisX.setPoint() 处，发现无法平移打印出的位置参数是 setData() 中设置进去的，
               但问题是为何后续没有变了呢？缩放能用的情况下，setPoint() 里直接插断电也没触发，
               此方法里是直接将位置赋值给 _point 变量的，因此可以可能有其他方法直接设置了 _point 变量，最终发现不生效是
               因为满足了重置方法直接 return 了：PinchScrollZoomAxis.prototype.checkAndResetToWithin()，
               将方法中的 "if (contentSize * scale < this._size) {" 这段代码整个注释掉后，平移不受限制了，或者把
               "this.axisX.checkAndResetToWithin(this.currentScale)、this.axisY.checkAndResetToWithin(this.currentScale)"
               两句调用删了也可以。
               新的方法：
               （1）将 doScale 方法中的 "this.checkWithin()" 注释掉即可实现平移和缩放。
               （2）缩放时应将当前鼠标位置 clientX、clientY（event || window.event）作为缩放中心点，
                   为此只需将 "PinchScrollZoomAxis.prototype.checkAndResetToWithin" 方法里的代码都删了，
                   重新根据鼠标位置来计算 this._point，为了确定当前 this 属于 axisX 还是 axisY，需要修改 "new PinchScrollZoomAxis(...)"
                   构造函数的参数，添加一个 _axisType 类型（值为：x 或 y），这样当处理的是 x 轴时我们用 clientX，处理 y 轴时我们用 clientY。
                   注意：测试OK后，我们直接在此 vue中复写 PinchScrollZoomAxis.prototype.checkAndResetToWithin，不去改插件源码。
           （2）当放大区域超过 width、height 时才可以进行平移图片。

      -->
      <PinchScrollZoom
          ref="zoomer"
          :width="windowInnerWidth"
          :height="windowInnerHeight"
          :contentWidth="$parent.originalImageInfo.image.width"
          :contentHeight="$parent.originalImageInfo.image.height"
          :scale="minScale"
          :minScale="0.1"
          :wheelVelocity="0.001"
          :draggable="draggable"
          @scaling="onScalingHandler"
          style="width:100%; height: 100vh;"
      >
        <!-- 这里必须在 canvas 外套一个 div 并设置高度，否则 缩放空间所在的 "transform" 属性处的高度会稍微高一点导致与 canvas 的高度不一致了。-->
        <div v-bind:style="{ height: $parent.originalImageInfo.image.height+'px' }">
          <canvas id="image-canvas" v-bind:style="{cursor:draggable?'move':'none'}"></canvas>
        </div>
      </PinchScrollZoom>
    </div>
    <div class="editor-toolkit-panel">
      <div class="editor-brush-slider">
        <span class="eraser-pen">笔</span>
        <b-form-input type="range" size="sm" class="input-brush-slider"
                      v-model="brushSize"
                      v-bind:step="step"
                      v-bind:min="minBrushSize"
                      v-bind:max="maxBrushSize"
                      v-on:update="onHandleSliderUpdate"
                      v-on:mouseup="onHandleSliderMouseUp"
                      v-on:touchend="onHandleSliderMouseUp">
        </b-form-input>
      </div>
      <div class="editor-toolkit-btns">
        <div role="button" class="tooltip-trigger btn-primary" data-state="closed" style="position: relative"
             v-bind:class="{'btn-primary-disabled':isInpainting,'btn-primary-active':draggable}"
             v-on:click="toggleDraggable">
          <svg width="200" height="200" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M768 320v128H576V256h128L512 64 320 256h128v192H256V320L64 512l64 64 128 128V576h192v192H320l192 192 64-64 128-128H576V576h192v128l192-192-192-192z"></path>
          </svg>
        </div>
        <div role="button" class="tooltip-trigger btn-primary btn-primary-reset-zoom" data-state="closed"
             v-bind:class="{'btn-primary-disabled':scale===minScale}"
             v-on:click="resetZoom">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
               stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round"
                  d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15"></path>
          </svg>
        </div>
        <div role="button" class="tooltip-trigger btn-primary" data-state="closed"
             v-bind:class="{'btn-primary-disabled':disableUndo}"
             v-on:click="undo">
          <svg width="19" height="9" viewBox="0 0 19 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2 1C2 0.447715 1.55228 0 1 0C0.447715 0 0 0.447715 0 1H2ZM1 8H0V9H1V8ZM8 9C8.55228 9 9 8.55229 9 8C9 7.44771 8.55228 7 8 7V9ZM16.5963 7.42809C16.8327 7.92721 17.429 8.14016 17.9281 7.90374C18.4272 7.66731 18.6402 7.07103 18.4037 6.57191L16.5963 7.42809ZM16.9468 5.83205L17.8505 5.40396L16.9468 5.83205ZM0 1V8H2V1H0ZM1 9H8V7H1V9ZM1.66896 8.74329L6.66896 4.24329L5.33104 2.75671L0.331035 7.25671L1.66896 8.74329ZM16.043 6.26014L16.5963 7.42809L18.4037 6.57191L17.8505 5.40396L16.043 6.26014ZM6.65079 4.25926C9.67554 1.66661 14.3376 2.65979 16.043 6.26014L17.8505 5.40396C15.5805 0.61182 9.37523 -0.710131 5.34921 2.74074L6.65079 4.25926Z"
                fill="currentColor"></path>
          </svg>
        </div>
        <div role="button" class="tooltip-trigger btn-primary btn-primary-redo" data-state="closed"
             v-bind:class="{'btn-primary-disabled':disableRedo}"
             v-on:click="redo">
          <svg width="19" height="9" viewBox="0 0 19 9" fill="none" xmlns="http://www.w3.org/2000/svg"
               style="transform: scale(-1, 1);">
            <path
                d="M2 1C2 0.447715 1.55228 0 1 0C0.447715 0 0 0.447715 0 1H2ZM1 8H0V9H1V8ZM8 9C8.55228 9 9 8.55229 9 8C9 7.44771 8.55228 7 8 7V9ZM16.5963 7.42809C16.8327 7.92721 17.429 8.14016 17.9281 7.90374C18.4272 7.66731 18.6402 7.07103 18.4037 6.57191L16.5963 7.42809ZM16.9468 5.83205L17.8505 5.40396L16.9468 5.83205ZM0 1V8H2V1H0ZM1 9H8V7H1V9ZM1.66896 8.74329L6.66896 4.24329L5.33104 2.75671L0.331035 7.25671L1.66896 8.74329ZM16.043 6.26014L16.5963 7.42809L18.4037 6.57191L17.8505 5.40396L16.043 6.26014ZM6.65079 4.25926C9.67554 1.66661 14.3376 2.65979 16.043 6.26014L17.8505 5.40396C15.5805 0.61182 9.37523 -0.710131 5.34921 2.74074L6.65079 4.25926Z"
                fill="currentColor"></path>
          </svg>
        </div>
        <div role="button" class="tooltip-trigger btn-primary" data-state="closed"
             v-bind:class="{'btn-primary-disabled':(!batchResult||(!batchResult.url&&!batchResult.OssUrl))||isInpainting}"
             v-on:click="downloadImage">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
               stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round"
                  d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"></path>
          </svg>
        </div>
        <div role="button" class="tooltip-trigger btn-primary" data-state="closed"
             v-bind:class="{'btn-primary-disabled':curLineGroup.length===0||isInpainting||isWebsocketConnectFailure}"
             v-on:click="runInpainting">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2 13L1.34921 12.2407C1.16773 12.3963 1.04797 12.6117 1.01163 12.8479L2 13ZM22.5 4L23.49 4.14142C23.5309 3.85444 23.4454 3.5638 23.2555 3.3448C23.0655 3.1258 22.7899 3 22.5 3V4ZM12.5 4V3C12.2613 3 12.0305 3.08539 11.8492 3.24074L12.5 4ZM1 19.5L0.0116283 19.3479C-0.0327373 19.6363 0.051055 19.9297 0.241035 20.1511C0.431014 20.3726 0.708231 20.5 1 20.5V19.5ZM11.5 19.5V20.5C11.7373 20.5 11.9668 20.4156 12.1476 20.2619L11.5 19.5ZM21.5 11L22.1476 11.7619C22.3337 11.6038 22.4554 11.3831 22.49 11.1414L21.5 11ZM2 14H12.5V12H2V14ZM13.169 13.7433L23.169 4.74329L21.831 3.25671L11.831 12.2567L13.169 13.7433ZM22.5 3H12.5V5H22.5V3ZM11.8492 3.24074L1.34921 12.2407L2.65079 13.7593L13.1508 4.75926L11.8492 3.24074ZM1.01163 12.8479L0.0116283 19.3479L1.98837 19.6521L2.98837 13.1521L1.01163 12.8479ZM1 20.5H11.5V18.5H1V20.5ZM12.4884 19.6521L13.4884 13.1521L11.5116 12.8479L10.5116 19.3479L12.4884 19.6521ZM21.51 3.85858L20.51 10.8586L22.49 11.1414L23.49 4.14142L21.51 3.85858ZM20.8524 10.2381L10.8524 18.7381L12.1476 20.2619L22.1476 11.7619L20.8524 10.2381Z"
                fill="currentColor"></path>
          </svg>
        </div>
      </div>
    </div>
    <div v-if="showRefBrush" class="brush-shape" v-bind:style=getBrushStyle()></div>
    <div v-if="!draggable&&showMouseBrush" class="brush-shape-mouse"></div>
    <div v-if="isWebsocketConnectFailure" id="websocketConnectFailureMessage">服务暂不可用</div>
    <div id="mouseClientPosition"></div>
  </section>
</template>

<script>

import $ from 'jquery';
// 设置默认语言
import * as FilePond2 from 'filepond';
import PinchScrollZoom, {PinchScrollZoomEmitData} from "@coddicat/vue-pinch-scroll-zoom";
import zh_CN from 'filepond/locale/zh-cn.js';
import "filepond/dist/filepond.min.css";
import ImageFile from "@/utils/imagefile";
import UploadCommon from "@/utils/upload.common";
import Common from "@/utils/common";

FilePond2.setOptions(zh_CN);

// 设置产品变量
let productCategory = 'IMAGE_CLEANER'  //产品类型
let maxFileSizeMBOfAnonymousUser = 999  //免费版文件上传限制，单位MB，999表示不限制
let maxFileSizeMBOfMembership = 999  //会员文件上传限制，单位MB，999表示不限制
let reduceSizeUntilMB = 15        //文件降到指定大小，单位MB，999表示不限制（原理是通过降低分辨率来实现的，若设置此值，那么不会去限制文件大小）
let reduceImageWidth = 3840
let reduceImageHeight = 2160

// Vue 本组件内有效
let self = null;

export default {
  name: 'IMAGE_CLEANER_CANVAS',
  data() {
    return {
      isWebsocketConnectFailure: false,
      imageCanvasElement: null,
      maskCanvasElement: null,
      canvasContext: null,
      file: null,
      downloadFile: null,
      renders: [], //修复图片 HTMLImageElement 集
      redoRenders: [],
      lineGroups: [],
      curLineGroup: [],
      redoLineGroups: [],
      redoCurLines: [],
      runMannually: true,
      isDraging: false,
      draggable: false,
      scale: null,
      isInpainting: false,
      showRefBrush: false,
      showMouseBrush: false,
      batchResult: null,
      brushSize: 60,
      BRUSH_COLOR: '#54c8ffbb',
      TOOLBAR_SIZE: 180
    };
  },
  computed: {
    windowInnerWidth() {
      return window.innerWidth
    },
    windowInnerHeight() {
      return window.innerHeight
    },
    step() {
      return ((this.maxBrushSize || 100) - (this.minBrushSize || 0)) / 100
    },
    minBrushSize() {
      return 10
    },
    maxBrushSize() {
      return Math.floor(this.$parent.originalImageInfo.image.width / 2)
    },
    minScale() {
      // 缩放因子
      const rW = window.innerWidth / this.$parent.originalImageInfo.image.width
      const rH = (window.innerHeight - this.TOOLBAR_SIZE) / this.$parent.originalImageInfo.image.height

      // minScale值默认是1，但如果图片很大，则加载后会被缩小，此时 minScale值就小于1了。
      // 获取长宽里的最小缩放因子（初始缩放因子，这里使用 长宽 中最小的缩放因子，以便大图可以显示全貌）
      let s = 1.0
      if (rW < 1 || rH < 1) {
        s = Math.min(rW, rH)
      }
      return s;
    },
    disableUndo() {
      //if (isInteractiveSeg) return true
      if (this.isInpainting) return true
      if (this.renders.length > 0) return false
      if (this.runMannually) {
        if (this.curLineGroup.length === 0) {
          return true
        }
      } else if (this.renders.length === 0) {
        return true
      }
      return false
    },
    disableRedo() {
      //if (isInteractiveSeg) return true
      if (this.isInpainting) return true
      if (this.redoRenders.length > 0) return false
      if (this.runMannually) {
        if (this.redoCurLines.length === 0) {
          return true
        }
      } else if (this.redoRenders.length === 0) {
        return true
      }
      return false
    }
  },
  created() {  // 模板渲染成html前调用
  },
  mounted() {  // 模板渲染成html后调用
    const self = this
    this.imageCanvasElement = document.getElementById("image-canvas")
    this.maskCanvasElement = document.createElement('canvas')
    this.changeImage(this.$parent.originalImageInfo.file)

    //手机端也有此 onmouseup 事件，为保险起见 ontouchend 也一起用上
    document.onmouseup = (e) => this.mouseupOrTouchendHandler(e)
    document.ontouchend = (e) => this.mouseupOrTouchendHandler(e)

    // 捕获 Websocket 消息自定义事件
    let eventNamePrefixList = ['GENERIC_BATCH_PROCESS_RESULT', 'GENERIC_ERROR', 'WEBSOCKET_CONNECT_SUCCESS', 'WEBSOCKET_CONNECT_FAILURE']
    for (let i = 0; i < eventNamePrefixList.length; i++) {
      let websocketType = eventNamePrefixList[i]
      let eventName = UploadCommon.getWebsocketCustomEventName(websocketType, productCategory)
      document.addEventListener(eventName, function (event) {
        let websocketType = eventName.split('__')[0]
        let batchResult = event.detail
        self.processWebsocketMessage(websocketType, batchResult)
      });
    }

    this.imageCanvasElement.oncontextmenu = function (e) {
      e.preventDefault();
      console.log('>>> 触发 imageCanvasElement oncontextmenu')
    }

    //手机上没有鼠标浮在元素上后离开的概念，因此无 onmouseleave 事件，也无需去找对应的手机上事件，直接忽略
    this.imageCanvasElement.onmouseleave = (ev) => self.showMouseBrush = false
    //手机上没有鼠标浮在元素上的概念，因此无 onmouseover 事件，也无需去找对应的手机上事件，直接忽略
    this.imageCanvasElement.onmouseover = (ev) => self.showMouseBrush = true

    this.overridePinchScrollZoom()

    // 图片进入时，显示毛玻璃效果
    $('.route-menu').css({'backdrop-filter': 'blur(12px)', 'background-color': 'hsla(0, 0%, 100%, 0.5)'})

    //手机上无 onmousemove 事件，但可以改用 ontouchmove
    document.onmousemove = (ev) => self.setBrushShapeMouse(ev)
    document.ontouchmove = (ev) => self.setBrushShapeMouse(ev)

    //监视屏幕大小，重新调整图片位置
    window.onresize = (ev) => self.onResize(ev)
  },
  updated() {
  },
  components: {PinchScrollZoom},
  methods: {
    overridePinchScrollZoom() {
      //作用：PinchScrollZoom 控件有些小细节无法满足要求，因此在这里重写。
      //注意：请将此方法放 mounted() 中，放 created() 里不起作用的。
      //改写：此方法改写仅针对 vue-pinch-scroll-zoom@3.0.1 版本，似乎也只有此版本支持 Vue2。

      // 重写1：不返回 transform-origin，否则 minScale 小于1时，放大图片再 resetZoom 无法居中。
      this.$refs.zoomer.getContainerStyle = function () {
        var x = this.axisX.point + "px";
        var y = this.axisY.point + "px";
        var translate = "translate(" + x + ", " + y + ") scale(" + this.currentScale + ")";
        var transformOrigin = this.axisX.origin + "px " + this.axisY.origin + "px";
        //console.log(`>>> translate：${translate}`)
        return {
          transform: translate,
          // "transform-origin": transformOrigin,   //此方法改写只需注释掉这行。
        };
      }

      // 重写2：防止平移时在 checkAndResetToWithin() 方法中找不到 clientY 而报错
      this.$refs.zoomer.doScale = function (touchEvent) {
        //注意：对于两个手指触控缩放，这个方法会触发多次，此方法在 doDragEvent() 中被调用，调用前会先调用 dragPinch() 设置 _point.

        if (touchEvent.touches.length < 2 || !this.touch1 || !this.touch2) {
          //this.checkWithin();  //此方法改写只需注释掉这行。
          return;
        }
        var touch1 = touchEvent.touches[0];
        var touch2 = touchEvent.touches[1];
        var distance = this.getDistance(this.getBoundingTouchClientX(touch1), this.getBoundingTouchClientY(touch1), this.getBoundingTouchClientX(touch2), this.getBoundingTouchClientY(touch2));
        var startDistance = this.getDistance(this.axisX.start1, this.axisY.start1, this.axisX.start2, this.axisY.start2);
        var scale = this.startScale * (distance / startDistance);

        this.submitScale(scale);
      }

      // 重写3：PinchScrollZoomAxis 构造函数里没有X轴，Y轴信息，缩放算法时要用到
      this.$refs.zoomer.axisX._axisType = 'x'
      this.$refs.zoomer.axisY._axisType = 'y'
      this.$refs.zoomer.axisX._zoomer = this.$refs.zoomer
      this.$refs.zoomer.axisY._zoomer = this.$refs.zoomer

      // 重写4：pinch-scroll-zoom__content 元素的宽、高必须与 canvas 一致，否则 translate 会有偏差
      let $pinchScrollZoomContent = $('.pinch-scroll-zoom__content')
      $pinchScrollZoomContent.css({'width': 'auto', 'height': 'auto'})

      // 重写5：本项目鼠标位置缩放不依赖于 transform-origin，因此不能使用 _origin，
      let pinchScrollZoomAxisPrototype = Object.getPrototypeOf(this.$refs.zoomer.axisX) //获取原型，用于重写原型上的方法
      pinchScrollZoomAxisPrototype.pinch = function (point1, point2, scale) {
        //提示：此方法仅在双指放上时触发，缩放期间不会触发
        this._start1 = point1;
        this._start2 = point2;
      };

      // 重写6：仅在手机端多点触控时才会触发此方法。
      pinchScrollZoomAxisPrototype.dragPinch = function (point1, point2) {
        /*
         提示：
            （1）目前 point1、point2 值就是 e.touche 上 clientX、clientY 的值.
            （2）但是，调用此方法时还没计算出新的 scale，调用 checkAndResetToWithin() 方法时已计算出新的 scale
         重要说明：
            （1）对于两个手指的缩放，缩放中心位置在两个手指之间，这个点类似于滚轮缩放时的唯一固定点，
                 因此这里仅在 clientX 的获取上需要计算下，剩下的都和 checkAndResetToWithin() 方法中的逻辑一样，直接拷贝。
         */

        var $el = document.getElementById('image-canvas')

        // 获取当前 scale，采用这种方式需修改插件源码，
        // 具体位置是：node_modules\@coddicat\vue-pinch-scroll-zoom\lib\index.esm.js 中的 doDragEvent() 方法，
        // 将 this.doScale(touchEvent); 移到 dragPinch() 方法调用前面。这样计算出 currentScale 后，此方法中就可以用了。
        // 改动后的脚本已放在此处做个备份：src\custom\node_modules\@coddicat\vue-pinch-scroll-zoom\lib\index.esm.js
        var scale = this._zoomer.currentScale

        // 采用这种方式提前计算 scale 虽然可以，但是缩放比较卡顿不流畅，因此放弃。改用直接修改插件源码 index.esm.js 中的 doDragEvent() 方法。
        // var zoomer = this._zoomer
        // var e = event || window.event;
        // var touch1 = e.touches[0];
        // var touch2 = e.touches[1];
        // var distance = zoomer.getDistance(zoomer.getBoundingTouchClientX(touch1), zoomer.getBoundingTouchClientY(touch1), zoomer.getBoundingTouchClientX(touch2), zoomer.getBoundingTouchClientY(touch2));
        // var startDistance = zoomer.getDistance(zoomer.axisX.start1, zoomer.axisY.start1, zoomer.axisX.start2, zoomer.axisY.start2);
        // var scale = zoomer.startScale * (distance / startDistance);
        // zoomer.submitScale(scale);

        // 保存图片原始尺寸，因为 scale 缩放比例基于这个原始值，要得到放大后的长宽尺寸只要用：this._originContentSize*scale
        if (!this._originContentSize) {
          this._originContentSize = this._contentSize
        }
        // 获取当前图片的长或宽（这个值会随着上次缩放而增大或缩小）
        var currentContentSize = this._contentSize

        if (this._axisType === 'x') {
          // 获取下一次缩放前，两个手指中间点的文档（客户）坐标
          var minPoint = Math.min(point1, point2)
          var maxPoint = Math.max(point1, point2)
          var clientX = minPoint + (maxPoint - minPoint) / 2

          //图片中心点与clientX所在图片中的点的距离
          var oldOffset = currentContentSize / 2 - (clientX - $el.getBoundingClientRect().left)
          // 计算这个距离在图片上的比例（这个比例无论怎么缩放都是固定的）
          var ratio = oldOffset / currentContentSize
          // 获取缩放后，图片的长或宽
          var newContentSize = this._originContentSize * scale
          // 根据刚才的固定比例，反向计算出缩放后，鼠标在图片中的位置（注意这个不是文档位置）
          var newOffset = newContentSize * ratio
          // 缩放前的距离 减去 缩放后的距离 就是本次要在 translate(x,y) 上移动的距离，以便鼠标在文档中的位置回到放大前的。
          var step = oldOffset - newOffset
          // 开始移动，this._point 变量就是 translate(x,y) 上的 x 或 y 值。(此方法的目的就是设置此 _point 值)
          this._point -= step
          // 最后不要忘了将新图片大小设置到 _contentSize，以便下次使用（y轴的处理和x轴类似，下面就不写备注了）
          this._contentSize = newContentSize
        } else if (this._axisType === 'y') {
          minPoint = Math.min(point1, point2)
          maxPoint = Math.max(point1, point2)
          var clientY = minPoint + (maxPoint - minPoint) / 2
          if (!clientY && clientY !== 0) return
          oldOffset = currentContentSize / 2 - (clientY - $el.getBoundingClientRect().top) //离中心点的位置
          ratio = oldOffset / currentContentSize
          newContentSize = this._originContentSize * scale
          newOffset = newContentSize * ratio
          step = oldOffset - newOffset
          this._point -= step
          this._contentSize = newContentSize
        }
      }

      // 重写7：鼠标位缩放算法
      pinchScrollZoomAxisPrototype.checkAndResetToWithin = function (scale) {

        // 图片在鼠标位置缩放有两种方法：
        //（1）修改 transform-origin 值进行缩放；
        //（2）不修改 transform-origin 值，而是设置 translate(x,y) 偏移量进行缩放，我们选择这种。
        // 原理分析：当图片在正中央时，translate 值为：translate(594.5px, 254.5px) scale(1)，此时若仅将 scale 扩大到 2，
        // 那么图片会在中心点向外扩展（没有使用 transform-origin 的话默认就用图片的中心位置），中心位置永远不会移动，借助这点，
        // 如果鼠标放在中心位置左上角45度的位置来放大图，若想让鼠标位置作为中心点，那么可以先计算鼠标在图片上的位置 mouseOffsetX，
        // 再算出原图中心点在图片上的位置 centerX（centerX=imageWidth/2），重点来了，此时先将 centerX-mouseOffsetX 得到
        // 鼠标与中心点的距离，这个距离在整个图片中的比例无论缩放都是不会变的，因此我们要算出这个比例：(centerX-mouseOffsetX)/imageWidth。
        // 有了放大前鼠标位置与中心点的比例位置后，那么放大后的鼠标在图片中的位置也就可以计算出来，计算出来后转成在文档坐标，
        // 再与放大前鼠标文档的位置一减，就得到距离差，这个距离差就是偏移量，在原来的  translate(x,y) 上加上这个偏移差就可以让因放大偏移的
        // 文档坐标移动回放大前的坐标位置。
        //参考文章：https://www.jianshu.com/p/750cfe9f31b4

        // 注意：对于鼠标缩放，此方法会触发两次，分别对应x、y轴；对于两个手指缩放，两个手指都相当于一个鼠标，因此会触发4次。

        var $el = document.getElementById('image-canvas')
        var e = event || window.event;
        if (!e) return;
        var isMultiTouch = e.touches && e.touches.length > 1

        // 保存图片原始尺寸，因为 scale 缩放比例基于这个原始值，要得到放大后的长宽尺寸只要用：this._originContentSize*scale
        if (!this._originContentSize) {
          this._originContentSize = this._contentSize
        }
        // 获取当前图片的长或宽（这个值会随着上次缩放而增大或缩小）
        var currentContentSize = this._contentSize

        if (this._axisType === 'x') {
          // 获取缩放前，鼠标与图片中心点的距离（这里的 "e.clientX - $el.getBoundingClientRect().left" 是将鼠标的文档坐标转成了图片中坐标）
          var clientX
          if (isMultiTouch) {
            return;
          } else {
            clientX = e.clientX ? e.clientX : null
          }
          var oldOffset = currentContentSize / 2 - (clientX - $el.getBoundingClientRect().left)
          // 计算这个距离在图片上的比例（这个比例无论怎么缩放都是固定的）
          var ratio = oldOffset / currentContentSize
          // 获取缩放后，图片的长或宽
          var newContentSize = this._originContentSize * scale
          // 根据刚才的固定比例，反向计算出缩放后，鼠标在图片中的位置（注意这个不是文档位置）
          var newOffset = newContentSize * ratio
          // 缩放前的距离 减去 缩放后的距离 就是本次要在 translate(x,y) 上移动的距离，以便鼠标在文档中的位置回到放大前的。
          var step = oldOffset - newOffset
          // 开始移动，this._point 变量就是 translate(x,y) 上的 x 或 y 值。(此方法的目的就是设置此 _point 值)
          this._point -= step
          // 最后不要忘了将新图片大小设置到 _contentSize，以便下次使用（y轴的处理和x轴类似，下面就不写备注了）
          this._contentSize = newContentSize
        } else if (this._axisType === 'y') {
          var clientY
          if (isMultiTouch) {
            return;
          } else {
            clientY = e.clientY ? e.clientY : null
          }
          if (!clientY && clientY !== 0) return
          oldOffset = currentContentSize / 2 - (clientY - $el.getBoundingClientRect().top) //离中心点的位置
          ratio = oldOffset / currentContentSize
          newContentSize = this._originContentSize * scale
          newOffset = newContentSize * ratio
          step = oldOffset - newOffset
          this._point -= step
          this._contentSize = newContentSize
        }
      };
    },
    onChangeImage(ev) {
      const newFile = ev.currentTarget.files?.[0]
      if (!newFile) return;
      this.changeImage(newFile)
    },
    onHandleDropImage(e) {
      e.preventDefault();

      const files = e.dataTransfer.files;
      if (files.length > 0) {
        const file = files[0];
        this.changeImage(file);
      }
    },
    changeImage(newFile) {
      let pondFile = {'file': newFile, 'filename': newFile.name}
      UploadCommon.checkFile(maxFileSizeMBOfAnonymousUser, maxFileSizeMBOfMembership, reduceSizeUntilMB, null, pondFile).then((file) => {
        // 先降体积，因为降体积实际上是在降分辨率，处理完后可能都不需要降分辨率。
        ImageFile.reduceImageResolutionForSize(file.file, file.file.reduceSizeUntilMB).then((reduceSizeFileInfo) => {
          let newFile1 = reduceSizeFileInfo.file
          let isSizeReduced = reduceSizeFileInfo.isReduced;
          if (isSizeReduced && newFile1.size >= file.file.size) isSizeReduced = false
          // 分辨率自动降低到服务器端的限制，并给用户提示。
          ImageFile.reduceImageResolution(newFile1, reduceImageWidth, reduceImageHeight).then((resolutionReduceFileInfo) => {
            let newFile2 = resolutionReduceFileInfo.file
            let isResolutionReduced = resolutionReduceFileInfo.isReduced;

            // 图片预处理的消息提醒
            let msg
            if (isSizeReduced && isResolutionReduced) {
              msg = `您上传的图片分辨率大于 ${reduceImageWidth}x${reduceImageHeight} 乘积，已降到 ${resolutionReduceFileInfo.width}x${resolutionReduceFileInfo.height}；体积过大，已降到 ${Math.ceil(newFile2.size / 1024 / 1024)}MB。`
            } else if (isSizeReduced) {
              msg = `您上传的图片过大，已降到 ${Math.ceil(newFile2.size / 1024 / 1024)}MB`
            } else if (isResolutionReduced) {
              msg = `您上传的图片分辨率大于 ${reduceImageWidth}x${reduceImageHeight} 乘积，已降到 ${resolutionReduceFileInfo.width}x${resolutionReduceFileInfo.height}。`
            }
            if (msg) this.Common.toastInfo(this.$bvToast, '提示', msg); //显示此消息客户反而有困惑

            // 获取图片信息，初始化画布
            ImageFile.getImageFileRatio(newFile2).then(ratio => {  //只需要用当前文件即可，此产品不可能会有批量上传的
              this.$parent.originalImageInfo = ratio
              this.$parent.originalImageInfo.file = newFile2
              this.$parent.originalImageInfo.name = newFile2.name
              this.$parent.originalImageInfo.type = newFile2.type
              this.file = newFile2
              this.downloadFile = newFile2
              this.initCanvas(this.$parent.originalImageInfo.image.width, this.$parent.originalImageInfo.image.height)
            })

          })
        })
      }, (error) => {
        if (error) console.log(error)
      })
    },

    initCanvas(canvasWidth, canvasHeight) {
      const self = this;

      // 设置 canvas 长宽
      this.lineGroups = []
      this.curLineGroup = []
      this.renders = []
      this.redoRenders = []
      this.redoLineGroups = []
      this.redoCurLines = []
      this.batchResult = null
      this.imageCanvasElement.width = canvasWidth
      this.imageCanvasElement.height = canvasHeight

      // 初始化笔刷大小
      this.brushSize = Math.floor(this.maxBrushSize / 4)

      this.resetZoom(canvasWidth, canvasHeight)
      this.drawOnCurrentRender([])

      //手机上无 onmousedown 事件，但可以改用 ontouchstart
      this.imageCanvasElement.onmousedown = (e) => this.mousedownOrTouchstartHandler(e)
      this.imageCanvasElement.ontouchstart = (e) => this.mousedownOrTouchstartHandler(e)
    },
    mousedownOrTouchstartHandler(e) {
      const self = this

      let pointXY;
      if (typeof TouchEvent !== 'undefined' && e instanceof TouchEvent) {  //必须加 "typeof TouchEvent !== 'undefined'"，否则火狐会报错
        pointXY = self.touchXY(e)
      } else {
        if (e.which !== 1) return  //仅支持鼠标左键操作
        pointXY = self.mouseXY(e)
      }
      if (self.draggable) return;

      self.isDraging = true
      let lineGroup = []
      if (self.runMannually) {
        lineGroup = [...self.curLineGroup]
      }
      lineGroup.push({size: self.brushSize, pts: [pointXY]})
      self.curLineGroup = lineGroup
      self.drawOnCurrentRender(lineGroup);

      //手机上无 onmousemove 事件，但可以改用 ontouchmove
      self.imageCanvasElement.onmousemove = (e) => this.mousemoveOrTouchmoveHandler(e)
      self.imageCanvasElement.ontouchmove = (e) => this.mousemoveOrTouchmoveHandler(e)
    },
    mousemoveOrTouchmoveHandler(e) {
      const self = this
      if (self.curLineGroup.length === 0) return

      let pointXY;
      if (typeof TouchEvent !== 'undefined' && e instanceof TouchEvent) {
        pointXY = self.touchXY(e)
      } else {
        if (e.which !== 1) return  //仅支持鼠标左键操作
        pointXY = self.mouseXY(e)
      }

      const lineGroup = [...self.curLineGroup]
      lineGroup[lineGroup.length - 1].pts.push(pointXY)
      self.curLineGroup = lineGroup
      self.drawOnCurrentRender(lineGroup);
    },
    mouseupOrTouchendHandler(e) {
      this.isDraging = false
      this.imageCanvasElement.onmousemove = "null"
      this.maskCanvasElement.onmousemove = "null"
      this.imageCanvasElement.ontouchmove = "null"
      this.maskCanvasElement.ontouchmove = "null"
    },

    draw(htmlImageElement, lineGroup) {
      //重要提示：参考 iopaint，每次绘制前都清理画布，并从变量 lineGroup 中重新绘制线条，这样可以保证绘制的线条不会有重叠区。

      let ctx = this.imageCanvasElement.getContext("2d")

      // 擦除指定矩形区域上绘制的图形，使用可参考：https://www.runoob.com/jsref/met-canvas-clearrect.html
      ctx.clearRect(0, 0, htmlImageElement.width, htmlImageElement.height)
      // 将用户上传的原始图片（render）绘制到 Canvas，这里的 naturalWidth、naturalHeight 等于图像本身的宽度和高度，
      // 画布上使用了原始图片，图片过大会导致在页面显示不全，尤其是手机版。此时可以给父元素添加 transform 来缩放达到目的，
      // iopaint项目就是这么做的。
      ctx.drawImage(htmlImageElement, 0, 0, htmlImageElement.width, htmlImageElement.height);
      // 绘制 Lines
      this.drawLines(ctx, lineGroup, this.BRUSH_COLOR)
    },
    drawOnCurrentRender(lineGroup) {
      if (this.renders.length === 0) {
        this.draw(this.$parent.originalImageInfo.image, lineGroup)
      } else {
        this.draw(this.renders[this.renders.length - 1], lineGroup)
      }
    },
    drawLines(ctx, lines, color) {
      ctx.strokeStyle = color
      ctx.lineCap = 'round'  // 线条末端线帽都使用圆形样式
      ctx.lineJoin = 'round' // 边角类型使用圆形样式

      lines.forEach(line => {
        if (!line?.pts.length || !line.size) {
          return
        }
        // 绘制线条参考：https://www.runoob.com/tags/canvas-beginpath.html
        ctx.lineWidth = line.size
        ctx.beginPath()
        ctx.moveTo(line.pts[0].x, line.pts[0].y)   //把路径移动到画布中的指定点，不创建线条，调用 stroke() 后才会画出来
        line.pts.forEach(pt => ctx.lineTo(pt.x, pt.y))  //lineTo是添加一个新点，然后创建从该点到画布中最后指定点的线条（该方法并不会创建线条）
        ctx.stroke()  //实际地绘制出通过moveTo() 和lineTo() 方法定义的路径。默认颜色是黑色。
      })
    },
    drawLinesOnMask(imageWidth, imageHeight, lineGroups, maskCanvasElement, color) {
      let maskCanvasContext = maskCanvasElement.getContext("2d")
      maskCanvasElement.width = imageWidth
      maskCanvasElement.height = imageHeight
      maskCanvasContext.clearRect(0, 0, imageWidth, imageHeight)
      this.drawLines(maskCanvasContext, lineGroups, color)
    },
    runInpainting() {
      let lineGroup = this.curLineGroup
      if (lineGroup.length === 0) return

      this.isInpainting = true

      let imgName = this.$parent.originalImageInfo.name;
      let imgWidth = this.$parent.originalImageInfo.image.width
      let imgheight = this.$parent.originalImageInfo.image.height
      let metadata = {"batchId": Common.generateUuid(), "batchSize": 1}

      // 1. 上传 Mask 图（每次上传Mask都根据 curLineGroup 在 Canvas 上重新绘制）
      this.drawLinesOnMask(imgWidth, imgheight, lineGroup, this.maskCanvasElement, this.BRUSH_COLOR)
      let maskDataUrl = this.maskCanvasElement.toDataURL("image/png");//base64，图片类型固定死
      let maskFileName = `${imgName.substring(0, imgName.lastIndexOf("."))}_mask.png`
      let maskFile = ImageFile.dataURLtoFile(maskDataUrl, maskFileName)
      let fileUuid = Common.generateUuid()  //约定：源图和Mask图的路径都使用同一个 uuid
      UploadCommon.uploadToAliyun('image_cleaner_upload', null, maskFile, metadata,
          null, null, null, null, true, `${fileUuid}_mask`).then((callbackReq) => {
        // 2.上传图片
        this.batchId = Common.generateUuid()
        let metadata = {"batchId": this.batchId, "batchSize": 1}
        let targetFile = this.file
        if (this.renders.length > 0) {
          const lastRender = this.renders[this.renders.length - 1]
          ImageFile.getFileFromUrl(lastRender.currentSrc, this.file.name, this.file.type).then((file) => {
            targetFile = file
            UploadCommon.uploadToAliyun('image_cleaner_upload', null, targetFile, metadata,
                null, null, null, null, false, fileUuid).then(() => {
              this.isDraging = false
              // Only append new LineGroup after inpainting success
              const newLineGroups = [...this.lineGroups, lineGroup]
              this.lineGroups = newLineGroups
              this.resetRedoState()
            }, (error) => {
              this.isDraging = false
              console.error(">>> 上传源图出错")
            })
          }, (error) => {
            this.isDraging = false
            console.error(">>> 上传源图出错")
          })
        } else {
          // 此段调用与上面重复，是直接拷贝的
          UploadCommon.uploadToAliyun('image_cleaner_upload', null, targetFile, metadata,
              null, null, null, null, false, fileUuid).then(() => {
            this.isDraging = false
            // Only append new LineGroup after inpainting success
            const newLineGroups = [...this.lineGroups, lineGroup]
            this.lineGroups = newLineGroups
            this.resetRedoState()
          }, (error) => {
            this.isDraging = false
            console.error(">>> 上传源图出错")
          })
        }

      }, (error) => {
        this.isInpainting = false
        console.error(">>> 上传Mask出错")
      })

    },
    downloadImage() {
      const downloadFile = this.downloadFile
      const url = this.batchResult.url || this.batchResult.OssUrl
      if (!this.downloadFile && !url) return;

      Common.downloadFile(url, this.$parent.originalImageInfo.name, this.downloadFile)
    },
    downloadMask() {
      let imgWidth = this.$parent.originalImageInfo.image.width
      let imgHeight = this.$parent.originalImageInfo.image.height
      let lineGroup = this.curLineGroup
      this.drawLinesOnMask(imgWidth, imgHeight, lineGroup, this.maskCanvasElement, 'white')
      const aDownloadLink = document.createElement('a')
      aDownloadLink.download = 'mask.jpg'
      aDownloadLink.href = this.maskCanvasElement.toDataURL('image/jpeg')
      aDownloadLink.click()
    },
    mouseXY(e) {
      // offsetX：以当前事件的目标对象左上角为原点，定位x轴坐标
      return {x: e.offsetX, y: e.offsetY}
    },
    touchXY(ev) {
      // touches: 当前屏幕上所有触摸点的列表;
      // targetTouches: 当前对象上所有触摸点的列表（但是 targetTouches[0].pageX 与 mouseEvent.offsetX 还是不一样的 ）

      // touchXY() 仅用在笔刷绘制时，此时只会用一个手指，因此仅处理 touches[0] 即可。
      const touch = ev.touches[0]  //不像 mouse 事件，touch 没有 e.offsetX，只有 e.clientX，因此需要转换
      const canvasElement = ev.target

      var $el = document.getElementById('image-canvas')
      var positionX = $el.getBoundingClientRect().left
      var positionY = $el.getBoundingClientRect().top

      // 计算 offset 卡了一两天，差值除以 scale 怎么就没想到，看了这个项目的源码才知道：
      // https://github.com/Anthony0416/zoom-canvas
      let scale = this.scale
      const offsetX = (touch.clientX - positionX) / scale
      const offsetY = (touch.clientY - positionY) / scale

      // 返回在图片中的位置
      return {x: offsetX, y: offsetY}
    },

    processWebsocketMessage(type, batchResult) {
      const self = this;

      let errors = batchResult ? batchResult.errorList : null;
      let errorSummary = errors && errors.length > 0 ? errors.join('。') : '处理出错'

      let originalFileName = this.$parent.originalImageInfo.name;
      let originalFileType = this.$parent.originalImageInfo.type;

      switch (type) {
        case 'GENERIC_BATCH_PROCESS_RESULT':
          if (batchResult.batchId === this.batchId) {
            if (batchResult.errorCount > 0) {
              this.isInpainting = false
              Common.toastDanger(this.$bvToast, '错误', batchResult.errorList.join(' ● '));
              return
            }
            var imgUrl = batchResult.url || batchResult.OssUrl
            ImageFile.getFileFromUrl(imgUrl, originalFileName, originalFileType).then(file => {
              this.downloadFile = file
              ImageFile.getImageFromFile(file).then(image => {
                this.isInpainting = false
                this.batchResult = batchResult
                this.curLineGroup = []
                this.renders.push(image)
                this.draw(image, [])
              }, error => {
                this.isInpainting = false
              })
            }, error => {
              this.isInpainting = false
            })
          } else {
            console.log('>>> 源图已被替换')
          }
          break;
        case 'GENERIC_ERROR':
          this.isInpainting = false
          Common.toastDanger(this.$bvToast, '错误', errorSummary);
          this.$parent.resetUpload()
          break;
        case 'WEBSOCKET_CONNECT_SUCCESS':
          this.isWebsocketConnectFailure = false
          break;
        case 'WEBSOCKET_CONNECT_FAILURE':
          this.isInpainting = false
          this.$parent.resetUpload()
          // 延迟100ms再设置连接失败，用于修复：火狐浏览器在切换或刷新页面一刹那，Websocket连接断开，触发此处调用，
          // 导致页面双向绑定显示"服务不可用"。
          setTimeout(() => {
            self.isWebsocketConnectFailure = true
          }, 2000)
          break;
      }
    },

    onHandleSliderUpdate(value) {
      this.brushSize = value
      if (!this.showRefBrush) {
        this.showRefBrush = true
      }
    },
    onHandleSliderMouseUp() {
      const self = this;
      setTimeout(() => {
        self.showRefBrush = false
      }, 200)
    },
    getBrushStyle(x, y) {
      if (!x && x !== 0) x = window.innerWidth / 2
      if (!y && y !== 0) y = window.innerHeight / 2

      const curScale = this.scale
      return {
        width: `${this.brushSize * curScale}px`,
        height: `${this.brushSize * curScale}px`,
        left: `${x}px`,
        top: `${y}px`,
        transform: 'translate(-50%, -50%)',
      }
    },
    setBrushShapeMouse(ev) {
      let self = this

      let clientX = ev.clientX ? ev.clientX : (ev.touches && ev.touches.length > 0 ? ev.touches[0].clientX : null)
      let clientY = ev.clientY ? ev.clientY : (ev.touches && ev.touches.length > 0 ? ev.touches[0].clientY : null)
      if (!clientX && clientX !== 0) return
      if (!clientY && clientY !== 0) return

      let brushWidth = self.brushSize * self.scale
      let top = clientY - Math.floor(brushWidth / 2)
      let left = clientX - Math.floor(brushWidth / 2)

      $('.brush-shape-mouse').css({
        'position': 'absolute', 'top': `${top}px`, 'left': `${left}px`,
        'width': `${brushWidth}px`, 'height': `${brushWidth}px`
      })

      // 此处打印坐标到页面上，已隐藏
      // document.getElementById('mouseClientPosition').innerHTML =
      //     `<div style="background-color:red;color:#fff">${Math.floor(clientX)}, ${Math.floor(clientY)}</div>`
    },

    undo() {
      if (this.runMannually && this.curLineGroup.length !== 0) {
        this.undoStroke()
      } else {
        this.undoRender()
      }
    },
    redo() {
      if (this.runMannually && this.redoCurLines.length !== 0) {
        this.redoStroke()
      } else {
        this.redoRender()
      }
    },
    undoStroke() {
      if (this.curLineGroup.length === 0) return

      let lastLine = this.curLineGroup.pop()
      this.redoCurLines = [...this.redoCurLines, lastLine]

      let newLineGroup = [...this.curLineGroup]
      this.curLineGroup = newLineGroup

      this.drawOnCurrentRender(newLineGroup);
    },
    undoRender() {
      // 此方法功能：对历史图片 HTMLImageElement 进行撤销，不是 Stroke 笔画，
      // 本项目仅当 curLineGroup 为空时才会这么触发，官方源码在 Editor.tsx。
      if (this.renders.length === 0) return

      // save line Group
      const latestLineGroup = this.lineGroups.pop()
      this.redoLineGroups = [...this.redoLineGroups, latestLineGroup]
      this.redoCurLines = []  //If render is undo, clear strokes

      this.lineGroups = [...this.lineGroups]
      this.curLineGroup = []
      this.isDraging = false

      // save render
      const lastRender = this.renders.pop()
      this.redoRenders = [...this.redoRenders, lastRender]

      const newRenders = [...this.renders]
      this.renders = newRenders
      if (newRenders.length === 0) {
        this.draw(this.$parent.originalImageInfo.image, [])
      } else {
        this.draw(newRenders[newRenders.length - 1], [])
      }
    },
    redoStroke() {
      if (this.redoCurLines.length === 0) return

      const line = this.redoCurLines.pop()
      this.redoCurLines = [...this.redoCurLines]

      const newLineGroup = [...this.curLineGroup, line]
      this.curLineGroup = newLineGroup

      this.drawOnCurrentRender(newLineGroup);
    },
    redoRender() {
      // 此方法功能：对历史图片 HTMLImageElement 进行恢复，不是 Stroke 笔画，
      // 本项目仅当 redoCurLines 为空时才会这么触发，官方源码在 Editor.tsx。
      if (this.redoRenders.length === 0) return

      const lineGroup = this.redoLineGroups.pop()
      this.redoLineGroups = [...this.redoLineGroups]
      this.lineGroups = [...this.lineGroups, lineGroup]
      this.curLineGroup = []
      this.isDraging = false

      const render = this.redoRenders.pop()
      const newRenders = [...this.renders, render]
      this.renders = newRenders
      this.draw(newRenders[newRenders.length - 1], [])
    },
    resetRedoState() {
      this.redoCurLines = []
      this.redoLineGroups = []
      this.redoRenders = []
    },

    toggleDraggable() {
      this.draggable = !this.draggable
    },
    resetZoom() {
      const original = this.$parent.originalImageInfo.image
      if (!this.minScale || !original || !window.innerWidth) {
        return
      }

      this.setCenter(original.width, original.height, this.minScale)
      this.scale = this.minScale
    },
    setCenter(canvasWidth, canvasHeight, scanle) {

      // 先不缩放，将图片居中，以获取图片原始 point 位置
      const positionX = window.innerWidth / 2 - (canvasWidth / 2)
      const positionY = window.innerHeight / 2 - (canvasHeight / 2)
      this.$refs.zoomer.setData({
        scale: scanle,
        translateX: positionX,
        translateY: positionY
      });

      this.$refs.zoomer.axisX._originContentSize = canvasWidth
      this.$refs.zoomer.axisX._contentSize = canvasWidth * this.minScale
      this.$refs.zoomer.axisY._originContentSize = canvasHeight
      this.$refs.zoomer.axisY._contentSize = canvasHeight * this.minScale
    },
    onScalingHandler(e) {
      this.scale = e.scale
      // 笔刷缩放思路：将缩放后圆的中心点 移动回鼠标文档位置。

      if (this.draggable || !this.showMouseBrush) return;
      let $brushShapeMouse = $('.brush-shape-mouse')
      if (!$brushShapeMouse) return

      //（1）绘制缩放后的圆形笔刷（绘制好后圆的中心点会移到其他地方，而不是在当前鼠标位置上）
      let newBrushWidth = this.brushSize * this.scale
      $brushShapeMouse.css('width', `${newBrushWidth}px`)
      $brushShapeMouse.css('height', `${newBrushWidth}px`)

      //（2）计算出偏移出去的圆的中心点的文档坐标
      let newCircularRadius = Math.floor(newBrushWidth / 2)
      let newCircularPositionX = $brushShapeMouse[0].offsetLeft + newCircularRadius
      let newCircularPositionY = $brushShapeMouse[0].offsetTop + newCircularRadius

      //（3）计算与缩放前的中心点的距离
      var ev = event || window.event;
      let clientX = ev.clientX ? ev.clientX : (ev.touches && ev.touches.length > 0 ? ev.touches[0].clientX : null)
      var clientY = ev.clientY ? ev.clientY : (ev.touches && ev.touches.length > 0 ? ev.touches[0].clientY : null)
      let offsetX = clientX - newCircularPositionX
      let offsetY = clientY - newCircularPositionY

      //（4）移动这个偏移距离即可将圆心回到当前的鼠标位置
      let top = parseInt($brushShapeMouse.css('top').replace('px', '')) + offsetY
      let left = parseInt($brushShapeMouse.css('left').replace('px', '')) + offsetX
      $brushShapeMouse.css('top', `${top}px`)
      $brushShapeMouse.css('left', `${left}px`)

      // document.getElementById('mouseClientPosition').innerHTML =
      //     `onScalingHandler: ${Math.floor(e.scale)}, ${Math.floor(e.translateX)}, ${Math.floor(e.translateY)}`
    },

    onResize(e) {
      const original = this.$parent.originalImageInfo.image
      if (!original) return
      this.setCenter(original.width, original.height, this.scale)
    }
  }
}

</script>

<style lang="scss" scoped>

.image-canvas-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  #image-canvas {
    position: relative;
    align-items: center;
    align-content: center;
    //top: 50%;
    //left: 50%;
    //background-color: #333;
    //-webkit-transform: translateX(-50%) translateY(-50%);
    //-moz-transform: translateX(-50%) translateY(-50%);
    //-o-transform: translateX(-50%) translateY(-50%);
    //-ms-transform: translateX(-50%) translateY(-50%);
    //transform: translateX(-50%) translateY(-50%);
  }

  .toolkit-panel {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    z-index: 2;
    margin-top: 13px;
    margin-left: 25px;
  }

  .editor-container {
    align-items: center;
    display: flex;
    //height: 100vw;
    justify-content: center;
    width: 100vw;
  }


  .editor-toolkit-panel {
    align-items: center;
    animation: slideUp .2s ease-out;
    -webkit-animation: slideUp .2s ease-out;
    -webkit-backdrop-filter: blur(12px);
    backdrop-filter: blur(12px);
    background-color: hsla(0, 0%, 100%, .5);
    border: 0;
    border-radius: 3rem;
    bottom: .5rem;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .102), 0 3px 16px rgba(0, 0, 0, .078), 0 2px 6px 1px rgba(0, 0, 0, .09);
    display: flex;
    gap: 15px;
    justify-content: center;
    padding: .4rem 32px;
    position: fixed;
  }

  .editor-brush-slider {
    align-items: center;
    display: grid;
    grid-column-gap: .6rem;
    -webkit-column-gap: .6rem;
    column-gap: .6rem;
    grid-area: toolkit-brush-slider;
    grid-template-columns: repeat(2, -webkit-max-content);
    grid-template-columns: repeat(2, max-content);
    height: -webkit-max-content;
    height: max-content;
    -webkit-user-select: none;
    user-select: none;

    .eraser-pen {
      color: #000;
      font-weight: 500;
    }

    input {
      color: inherit;
      line-height: 1.5;
      height: 1.5em;
      padding: .25em 0;
    }

    input[type=range] {
      -webkit-appearance: none;
      appearance: none;
      background: transparent;
      border-color: transparent;
      color: transparent;
      cursor: pointer;
      width: 100%;
    }

    /* 定义range控件轨道的样式 */
    [type="range"]::-webkit-slider-runnable-track {
      height: 0.2rem;
      //background: red;
    }

    /* 定义range控件容器的样式 */
    //[type="range" i]::-webkit-slider-container {
    //  height: 20px;
    //}

    /* WebKit 浏览器（如 Chrome 和 Safari）样式 */
    input[type="range"]::-webkit-slider-thumb {
      -webkit-appearance: none;
      //width: 15px;
      //height: 15px;
      background: #54c8ff;
      box-shadow: none;
      cursor: pointer;
      //border: 4px solid #333;
      margin-top: -0.36rem;
    }

    /* Firefox 样式 */
    input[type="range"]::-moz-range-thumb {
      -webkit-appearance: none;
      //width: 15px;
      //height: 15px;
      background: #54c8ff;
      box-shadow: none;
      cursor: pointer;
      //border: 4px solid #333;
      margin-top: -0.36rem;
    }

    /* IE 和 Edge 样式 */
    input[type="range"]::-ms-thumb {
      -webkit-appearance: none;
      //width: 15px;
      //height: 15px;
      background: #54c8ff;
      box-shadow: none;
      cursor: pointer;
      //border: 4px solid #333;
      margin-top: -0.36rem;
    }

    input[type="range"]:focus::-webkit-slider-thumb {
      //box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem #a7e3ff;
    }

    //input[type='range']::-webkit-slider-thumb {
    //  -webkit-appearance: none;
    //}
    //
    //input[type='range']:focus {
    //  outline: none;
    //}

    //input[type='range']::-webkit-slider-thumb {
    //  -webkit-appearance: none;
    //  height: 1.2rem;
    //  width: 1.2rem;
    //  border-radius: 50%;
    //  border: 1px solid rgb(0, 0, 0);
    //  z-index: 2;
    //  background: var(--yellow-accent);
    //  margin-top: -0.5rem;
    //}

    //input[type='range']::-webkit-slider-runnable-track {
    //  border-radius: 2rem;
    //  height: 0.2rem;
    //  background: var(--slider-background-color);
    //}
    //
    //input[type='range']::-moz-range-track {
    //  border-radius: 2rem;
    //  background: var(--slider-background-color);
    //}
    //
    //input[type='range']::-moz-range-progress {
    //  background: var(--yellow-accent);
    //}
  }

  .editor-toolkit-btns {
    display: flex;
    gap: 6px;
  }

  .tooltip-trigger {
    align-items: center;
    display: flex;
    justify-content: center;
    //background-color: transparent;
  }

  .input-brush-slider {
    //启用单指垂直平移手势，言外之意是不启用 水平手势，这会导致触发有的浏览器左右翻页行为
    touch-action: pan-y;
    //设置此 manipulation 属性后，iphone上缩放中心点也正确了，之前一直有偏离
    //touch-action: manipulation;
  }

  .btn-primary {
    grid-column-gap: 1rem;
    background-color: #fff;
    border-radius: .5rem;
    color: #040404;
    -webkit-column-gap: 1rem;
    column-gap: 1rem;
    cursor: pointer;
    display: grid;
    font-family: WorkSans, sans-serif;
    grid-auto-flow: column;
    padding: .5rem;
    place-items: center;
    width: -webkit-max-content;
    width: max-content;
    z-index: 1;
    border-color: inherit;

    //图片进入时，显示毛玻璃效果
    backdrop-filter: blur(12px);
    background-color: hsla(0, 0%, 100%, 0.5);

    //启用单指垂直平移手势，言外之意是不启用 水平手势，这会导致触发有的浏览器左右翻页行为
    touch-action: pan-y;
    // manipulation表示浏览器只允许进行滚动和持续缩放操作，类似双击缩放这种非标准操作就不可以
    //设置此 manipulation 属性后，iphone上缩放中心点也正确了，之前一直有偏离
    //touch-action: manipulation;

    svg {
      //启用单指垂直平移手势，言外之意是不启用 水平手势，这会导致触发有的浏览器左右翻页行为
      touch-action: pan-y;
      //设置此 manipulation 属性后，iphone上缩放中心点也正确了，之前一直有偏离
      //touch-action: manipulation;
    }
  }

  .btn-primary:hover {
    background-color: #f1f1f1;
  }

  .btn-primary:active {
    color: #040404;
    background-color: #f1f1f1;
  }

  .btn-primary:focus {
    box-shadow: none;
  }

  .btn-primary-disabled {
    background-color: #fff;
    opacity: .5; // 把svg状态设为disable样式
    pointer-events: none;
  }

  .btn-primary-active:hover {
    background-color: #CCEAFFBB;
  }

  .btn-primary-active {
    color: #040404;
    background-color: #CCEAFFBB;
  }

  .btn-primary svg {
    height: auto;
    width: 20px;
  }

  .editor-canvas-loading {
    pointer-events: none;
    animation: pulsing 750ms infinite; //脉冲效果
  }

  .editor-canvas-draggable {
    cursor: pointer;
  }

  .brush-shape {
    position: absolute;
    border-radius: 50%;
    background-color: #54c8ffbb;
    pointer-events: none;
    overflow: hidden;
  }

  .brush-shape-mouse {
    position: absolute;
    border-radius: 50%;
    background-color: #54c8ffbb;
    pointer-events: none;
    overflow: hidden;
    cursor: none;
  }

  //脉冲效果
  @keyframes pulsing {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.75;
      background-color: var(--animation-pulsing-bg);
    }
    100% {
      opacity: 1;
    }
  }

  #websocketConnectFailureMessage {
    position: fixed;
    top: 70px;
    display: inline-block;
    padding: 8px 15px;
    background-color: #db2828;
    color: #fff;
    font-weight: 500;
    border-radius: 8px;
    -webkit-border-radius: 8px;
  }

  #mouseClientPosition {
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #e8e8e8;
    z-index: 999;
  }
}


@media only screen and (max-width: 601px) {
  .image-canvas-container {
    .editor-toolkit-panel {
      padding: .4rem 1rem;
    }
  }

  .editor-brush-slider {
    input[type=range] {
      width: 80px !important;
    }
  }
}

@media only screen and (max-width: 430px) {
  .btn-primary-reset-zoom {
    display: none !important;
  }
}

@media only screen and (max-width: 371px) {
  .btn-primary-redo {
    display: none !important;
  }
  .btn-primary-reset-zoom {
    display: none !important;
  }
}
</style>
